import React from 'react';

const launchLogo = () => {
  return (
    <svg width="140" height="39" viewBox="0 0 140 39" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8965 0.729797L11.5927 19.2897H15.1613L20.7489 4.04026L26.3022 19.2897H29.8698L22.566 0.729797H18.8965Z" />
      <path d="M21.3407 25.9515V38.3324H19.9413V25.9515H21.3407ZM24.1396 22.142V34.5229H22.7401V22.142H24.1396ZM18.5416 22.142V34.5229H17.1422V22.142H18.5416Z" />
      <path d="M78.1276 0.475586C81.722 0.475586 84.8391 2.37237 86.5216 5.2018L86.6664 5.45385L84.01 6.84539C82.9027 4.80481 80.6895 3.47478 78.1276 3.47478C74.3952 3.47478 71.4542 6.32004 71.4542 9.99953C71.4542 13.6794 74.3952 16.5239 78.1276 16.5239C80.6011 16.5239 82.7496 15.2844 83.8915 13.3619L84.01 13.1533L86.6664 14.5449C85.0221 17.5135 81.8277 19.5231 78.1276 19.5231C72.751 19.5231 68.3867 15.2564 68.3867 9.99953C68.3867 4.74296 72.751 0.475586 78.1276 0.475586ZM34.0062 0.730186V12.7232C34.0062 15.0104 35.8642 16.7105 38.3835 16.7105C40.8287 16.7105 42.6807 15.109 42.7875 12.9234L42.7923 12.7232V0.730186H45.8781V13.2485C45.8781 16.8342 42.6662 19.5231 38.3835 19.5231C34.1882 19.5231 31.0507 16.9428 30.9244 13.4669L30.9204 13.2485V0.730186H34.0062ZM52.191 0.730529L62.3937 14.4951V0.730529H65.3652V19.2898H62.3627L52.191 5.49371V19.2898H49.1885V0.730529H52.191ZM3.12128 0.730529V16.4748H9.84177V19.2898H0V0.730529H3.12128Z" />
      <path d="M111.343 0.475677V12.6311C111.343 14.9494 113.196 16.6725 115.709 16.6725C118.15 16.6725 119.999 15.0465 120.102 12.8284L120.107 12.6311V0.475677H123.185V13.1636C123.185 16.7979 119.981 19.5232 115.709 19.5232C111.523 19.5232 108.392 16.9058 108.268 13.3805L108.265 13.1636V0.475677H111.343ZM134.008 0.476026C136.988 0.476026 139.379 2.48226 139.379 5.58616C139.379 7.59274 138.416 9.00348 136.895 9.75568C138.727 10.4456 140 11.9503 140 14.239C140 17.2672 137.754 19.1913 134.905 19.2833L134.691 19.2868H126.681V0.476026H134.008ZM92.652 0.476026V8.43897H101.813V0.476026H104.941V19.2868H101.813V11.2921H92.652V19.2868H89.5238V0.476026H92.652ZM134.256 11.2295H129.692V16.4336H134.256C135.746 16.4336 136.957 15.3363 136.957 13.8629C136.957 12.444 135.834 11.3158 134.42 11.2342L134.256 11.2295ZM133.666 3.32884H129.692V8.533H133.666C135.125 8.533 136.336 7.373 136.336 5.89958C136.336 4.45055 135.213 3.4084 133.827 3.3332L133.666 3.32884Z" />
      <path d="M120.534 25.0757C121.442 25.0757 122.202 25.5091 122.623 26.0648L122.107 26.4846C121.795 26.0376 121.226 25.7255 120.548 25.7255C119.734 25.7255 118.975 26.1728 118.975 27.0941C118.975 29.1932 123.029 28.5434 123.029 31.2391C123.029 32.6609 121.863 33.42 120.548 33.42C119.341 33.42 118.351 32.7559 117.958 31.8077L118.555 31.4691C118.839 32.2282 119.571 32.7696 120.562 32.7696C121.551 32.7696 122.378 32.2282 122.378 31.2664C122.378 29.1121 118.324 29.7489 118.324 27.1346C118.324 25.8205 119.368 25.0757 120.534 25.0757ZM81.5043 25.1844V30.5345C81.5043 31.8077 82.5074 32.7696 83.8228 32.7696C85.1515 32.7696 86.1549 31.8077 86.1549 30.5345V25.1844H86.806V30.6296C86.806 32.2146 85.5315 33.42 83.8228 33.42C82.1281 33.42 80.8536 32.2146 80.8536 30.6296V25.1844H81.5043ZM111.099 25.1844V25.8205H107.411V28.9359H110.638V29.5591H107.411V32.6745H111.099V33.3116H106.76V25.1844H111.099ZM97.1896 25.1844C98.4239 25.1844 99.4133 26.1728 99.4133 27.4055C99.4133 28.5298 98.5726 29.4375 97.4877 29.5591L99.9146 33.3116H99.1691L96.8233 29.5863H95.0604V33.3116H94.41V25.1844H97.1896ZM74.1028 25.1844V25.8342H71.8113V33.3116H71.1606V25.8342H68.869V25.1844H74.1028ZM56.1802 25.1844L61.4413 32.16V25.1844H62.092V33.3116H61.5225L56.2617 26.3353V33.3116H55.611V25.1844H56.1802ZM48.1686 25.1844V25.8205H44.4804V28.9359H47.7078V29.5591H44.4804V32.6745H48.1686V33.3116H43.8297V25.1844H48.1686ZM31.2357 25.1844L33.8117 32.4854L36.388 25.1844H37.1066L34.1643 33.3116H33.4457L30.5031 25.1844H31.2357ZM97.1487 25.8342H95.0604V28.9632H97.1487C98.0576 28.9632 98.7626 28.2998 98.7626 27.4055C98.7626 26.4982 98.0576 25.8342 97.1487 25.8342Z" />
    </svg>
  );
};

export default launchLogo;
