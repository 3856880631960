import React from "react";

const qsnLogo = () => {
  return (
    <svg width="141" height="44" viewBox="0 0 141 44" xmlns="http://www.w3.org/2000/svg">
      <path d="M71.2781 43.0469C63.9524 42.9907 57.3984 40.8277 51.6685 36.1837C51.472 36.0206 51.2855 35.846 51.1098 35.6608L52.6792 34.0644C52.6792 34.0644 53.2466 34.4246 53.7586 34.8046C58.5828 38.389 63.9685 40.4558 70.0171 40.4871C74.0413 40.5083 78.0013 39.9387 81.636 38.071C89.0943 34.2379 90.7257 23.7349 84.7515 18.0939C82.2333 15.7157 79.0923 14.6014 75.7573 13.9822C73.4469 13.5534 71.1103 13.4425 68.7679 13.7539C66.9447 13.9961 65.4971 15.1192 65.1499 16.5631C64.6394 18.6934 66.133 20.4932 68.7336 20.7616C71.0593 21.0015 73.3966 21.1335 75.7281 21.3282C78.3251 21.547 80.4218 23.2389 80.8921 25.4778C81.4661 28.2104 80.3452 30.6557 77.9021 31.9414C75.4386 33.2366 72.776 33.5087 70.0485 33.3745C65.7822 33.1659 61.777 32.1741 58.3071 29.5297C51.605 24.4248 50.0487 15.4174 54.7818 8.90132C57.7142 4.86403 61.8631 2.73891 66.6471 1.93525C74.3928 0.634211 81.5244 2.51867 88.1609 6.55742C88.2982 6.64819 88.4236 6.75574 88.5343 6.87757L87.2901 8.53668C87.2901 8.53668 86.7796 8.2807 86.1327 7.95326C82.3821 6.03307 78.4833 4.55336 74.2557 4.12746C69.6802 3.66582 65.2593 4.24925 61.2745 6.63764C56.866 9.27909 54.6381 13.258 55.0027 18.4097C55.3506 23.3155 58.0052 26.8161 62.3947 28.9361C66.4254 30.8818 70.7085 31.1239 75.063 30.3115C76.6747 30.0118 78.1413 28.1156 78.0377 26.5922C77.9356 25.0877 76.4829 23.8406 74.6714 23.667C72.0533 23.4169 69.4075 23.4483 66.8178 22.9151C64.0429 22.3478 62.437 20.6471 62.2437 18.1019C62.0301 15.2898 63.3501 13.1727 66.0039 12.2035C71.1883 10.3074 76.2357 11.0629 81.186 13.1005C84.7668 14.5737 87.7692 16.7615 89.5633 20.2941C93.0966 27.2522 90.4858 35.8176 83.5897 39.9737C79.8026 42.2549 75.6494 43.0804 71.2781 43.0469Z" />
      <path d="M15.8086 27.7097C18.1124 26.3058 20.651 25.8398 23.3027 26.3576C26.1425 26.9126 28.2618 28.6687 30.1696 30.7187C31.0753 31.693 31.9592 32.6877 33.059 33.4593C35.7106 35.3241 38.3922 35.1294 40.7441 32.8912C41.9992 31.6974 42.8189 30.2395 43.2791 28.5885C44.5269 24.1187 44.3642 19.587 42.9283 15.2762C40.3131 7.42404 34.7378 2.59184 26.6296 0.679669C26.4765 0.643205 26.3379 0.606741 26.2052 0.573924C24.7816 0.304424 23.336 0.167705 21.8871 0.165527C9.79932 0.165527 6.61449e-07 9.96485 6.61449e-07 22.0527C-0.000179818 22.7906 0.0365732 23.5281 0.110122 24.2624C0.19326 24.7729 0.279315 25.1229 0.294629 25.2199C1.08371 30.214 3.28467 34.5182 6.98795 37.9823C8.75427 39.6324 10.6516 40.9432 12.68 41.9146C15.5646 43.2529 18.7071 43.9442 21.8871 43.9398C24.2165 43.9318 26.5323 43.5841 28.7613 42.9079C31.3218 42.0553 33.6132 40.642 35.8711 38.9931C36.164 38.7766 36.4382 38.5358 36.6908 38.2733L35.1593 36.7279C35.1593 36.7279 35.074 36.8133 34.8034 37.0532C30.3125 41.0416 24.9049 42.2887 19.1159 41.6477C11.4825 40.8068 3.22487 33.3908 2.52914 24.1588C1.87862 15.5373 6.39432 8.70976 12.5604 5.41634C19.6854 1.61096 28.6643 1.9158 35.7267 8.3196C40.5742 12.7172 42.2822 18.2728 41.4223 24.6649C41.1678 26.5567 40.8236 28.4477 39.6954 30.0711C38.4556 31.8571 36.1715 32.2713 34.3753 31.0476C33.5045 30.4547 32.8394 29.6554 32.2093 28.8277C31.3651 27.7126 30.3732 26.7175 29.2609 25.8697C26.0564 23.4383 22.4246 23.0882 18.6032 23.7738C16.5262 24.1464 14.5863 24.8312 13.0774 26.4043C11.1295 28.4346 11.085 31.2379 12.9812 33.3069C13.7549 34.1514 14.6374 34.8661 15.6729 35.3642C20.5205 37.6979 25.2455 37.1779 29.8377 34.6561C30.209 34.4514 30.5546 34.2034 30.8675 33.9173L29.126 32.116C29.126 32.116 28.665 32.3348 28.3019 32.5346C24.1391 34.8581 19.824 35.0645 15.7094 32.3267C13.593 30.9192 13.6507 29.0253 15.8086 27.7097Z" />
      <path d="M30.9593 10.9539C24.3914 5.83512 16.1593 7.9194 11.6932 11.9231C6.15065 16.8932 6.81356 25.9253 9.69276 30.4928C9.70078 30.3943 9.70443 30.2959 9.70588 30.1974C9.64663 29.8505 9.61687 29.4992 9.61691 29.1473C9.61691 27.9804 9.93488 26.912 10.4541 26.112C10.4454 26.077 10.4352 26.0391 10.4228 26.007C10.0501 24.9459 9.96988 23.2357 9.9852 22.0382C10.3951 15.7912 15.4132 9.33566 24.1332 10.8693C27.5462 11.4703 30.5997 12.9011 32.2472 16.1245C33.7473 19.0679 34.4182 22.3358 33.4344 25.5446C33.2507 26.1441 33.0122 26.9711 33.0122 26.9711C33.0122 26.9711 34.9032 28.8249 34.9885 28.8723C37.7044 23.6704 37.0772 15.722 30.9593 10.9539Z" />
      <path d="M120.287 39.4262C124.48 39.2519 128.039 37.614 131.11 34.8617C131.343 34.6523 131.508 34.4751 131.508 34.4751L133.814 36.7359C133.336 37.266 132.812 37.7517 132.247 38.1872C122.175 46.0481 107.415 42.9785 101.325 31.7564C95.2052 20.4722 100.981 6.29141 113.253 2.41164C113.576 2.30955 114.069 2.18338 114.069 2.18338L114.061 5.51254C114.061 5.51254 113.459 5.76925 112.913 5.99241C105.999 8.80888 101.624 15.7531 102.095 22.97C102.588 30.5049 107.661 36.7585 114.899 38.7122C115.81 38.9465 116.736 39.1204 117.67 39.233C118.536 39.3475 119.414 39.3657 120.287 39.4262Z" />
      <path d="M130.782 5.00133C128.561 3.57413 126.283 2.29278 123.652 1.77427C122.923 1.64024 122.185 1.55787 121.444 1.52777L121.501 4.78109C121.501 4.78109 121.835 4.79421 122.164 4.83797C124.143 5.09832 126.048 5.64893 127.753 6.7042C133.707 10.39 136.748 15.7662 136.973 22.7228C137.028 24.4366 136.985 26.1628 136.207 27.7607C135.735 28.7292 135.692 28.7365 134.888 28.0072C129.146 22.7943 123.357 17.631 117.697 12.3277C116.834 11.5196 116.523 11.3679 115.655 10.6357H111.782C111.782 10.6875 111.769 11.058 111.772 11.3577C111.811 15.7983 111.791 20.2404 111.791 24.6817C111.791 27.0416 111.807 30.8616 111.784 33.4505H115.453C115.422 28.1895 115.437 21.3882 115.437 16.2147V14.9268C115.763 15.2142 115.882 15.3133 115.995 15.4191C122.451 21.44 128.902 27.467 135.347 33.5001C135.944 34.0616 136.166 34.0048 136.607 33.3681C142.096 25.4328 141.526 11.9069 130.782 5.00133Z" />
    </svg>
  );
};

export default qsnLogo;
