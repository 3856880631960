import React from "react";

const rockawayLogo = () => {
  return (
    <svg width="140" height="36" viewBox="0 0 140 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.5447 3.17592H31.0715V4.3681C31.0715 4.65667 31.0211 4.88296 31.0211 4.88296H31.046C31.3595 3.91656 32.0628 3.1261 33.0541 3.1261C33.2425 3.1261 33.4179 3.16347 33.4179 3.16347V3.69078C33.4179 3.69078 33.2674 3.66587 33.1294 3.66587C32.1505 3.66587 31.5355 4.49422 31.2588 5.42273C31.1208 5.86182 31.0704 6.32633 31.0704 6.74051V9.45131H30.5436V3.17592H30.5447Z" />
      <path d="M40.5603 3.02478C42.355 3.02478 43.8103 4.44272 43.8103 6.27536C43.8103 8.14536 42.3545 9.60068 40.5603 9.60068C38.7655 9.60068 37.3097 8.14485 37.3097 6.27536C37.3097 4.44324 38.7655 3.02478 40.5603 3.02478ZM40.5603 9.08685C42.0535 9.08685 43.2581 7.86977 43.2581 6.27588C43.2581 4.71936 42.0535 3.53964 40.5603 3.53964C39.0666 3.53964 37.8619 4.71936 37.8619 6.27588C37.8619 7.86925 39.0666 9.08685 40.5603 9.08685Z" />
      <path d="M51.4896 3.02478C52.9828 3.02478 53.7358 3.91593 53.7358 3.91593L53.4099 4.34204C53.4099 4.34204 52.6947 3.53912 51.4896 3.53912C50.0213 3.53912 48.8037 4.63113 48.8037 6.3257C48.8037 7.99485 50.0213 9.08634 51.4896 9.08634C52.8701 9.08634 53.5853 8.18273 53.5853 8.18273L53.8614 8.62234C53.8614 8.62234 53.0456 9.6012 51.4896 9.6012C49.7197 9.6012 48.2514 8.32131 48.2514 6.3257C48.2514 4.3301 49.7452 3.02478 51.4896 3.02478Z" />
      <path d="M58.7447 0.666016H59.2721V5.64854H60.389L62.4219 3.17596H63.1128L60.841 5.87431V5.89922L63.3261 9.45083H62.6607L60.389 6.13745H59.2721V9.45083H58.7447V0.666016Z" />
      <path d="M71.2792 5.62296H71.6939V5.40965C71.6939 4.01661 70.9781 3.53964 69.9744 3.53964C68.8829 3.53964 68.1796 4.14222 68.1796 4.14222L67.8786 3.71559C67.8786 3.71559 68.6317 3.0253 69.9998 3.0253C71.4427 3.0253 72.2212 3.82821 72.2212 5.39719V9.4512H71.6939V8.62286C71.6939 8.28446 71.7437 8.05817 71.7437 8.05817H71.7193C71.7312 8.05817 71.1536 9.60172 69.5228 9.60172C68.5061 9.60172 67.3892 9.03703 67.3892 7.78206C67.3886 5.64787 70.1617 5.62296 71.2792 5.62296ZM69.5472 9.11229C70.9153 9.11229 71.6939 7.69434 71.6939 6.45182V6.11291H71.2927C70.225 6.11291 67.9414 6.11291 67.9414 7.74417C67.9409 8.40902 68.4806 9.11229 69.5472 9.11229Z" />
      <path d="M76.6384 3.17575H77.2031L78.9096 8.09548C79.0103 8.3965 79.1234 8.77331 79.1234 8.77331H79.1483C79.1483 8.77331 79.2615 8.3965 79.3617 8.09548L81.0811 3.20066H81.6713L83.3908 8.09548C83.4914 8.3965 83.6046 8.77331 83.6046 8.77331H83.6295C83.6295 8.77331 83.7292 8.40948 83.8428 8.09548L85.5493 3.17575H86.1145L83.9056 9.45062H83.3404L81.5965 4.58124C81.4834 4.28021 81.3827 3.90341 81.3827 3.90341H81.3578C81.3578 3.90341 81.2576 4.28021 81.1445 4.58124L79.4125 9.45062H78.8478L76.6384 3.17575Z" />
      <path d="M93.9957 5.62296H94.4099V5.40965C94.4099 4.01661 93.6941 3.53964 92.6904 3.53964C91.5984 3.53964 90.8951 4.14222 90.8951 4.14222L90.5941 3.71559C90.5941 3.71559 91.3472 3.0253 92.7153 3.0253C94.1586 3.0253 94.9366 3.82821 94.9366 5.39719V9.4512H94.4093V8.62286C94.4093 8.28446 94.4592 8.05817 94.4592 8.05817H94.4348C94.4467 8.05817 93.8696 9.60172 92.2383 9.60172C91.2221 9.60172 90.1046 9.03703 90.1046 7.78206C90.1046 5.64787 92.8788 5.62296 93.9957 5.62296ZM92.2637 9.11229C93.6318 9.11229 94.4099 7.69434 94.4099 6.45182V6.11291H94.0086C92.9416 6.11291 90.6574 6.11291 90.6574 7.74417C90.6574 8.40902 91.1972 9.11229 92.2637 9.11229Z" />
      <path d="M99.4304 11.2838C99.4304 11.2838 99.7817 11.5843 100.208 11.5843C100.81 11.5843 101.187 11.045 101.438 10.4549L101.89 9.41325L99.2918 3.17575H99.857L101.927 8.18319C102.052 8.48474 102.154 8.77331 102.154 8.77331H102.179C102.179 8.77331 102.267 8.48474 102.392 8.18319L104.463 3.17575H105.027L101.802 10.9194C101.526 11.5718 100.999 12.0862 100.208 12.0862C99.6058 12.0862 99.1916 11.7348 99.1916 11.7348L99.4304 11.2838Z" />
      <path d="M0.016716 27.3457H2.97924C4.58974 27.3457 5.716 28.0666 5.716 29.4519C5.716 30.1281 5.38902 30.8153 4.78126 31.1646V31.1869C5.70458 31.4687 6.04246 32.2909 6.04246 33.0004C6.04246 34.7577 4.57832 35.3992 2.97924 35.3992H0.016716V27.3457ZM3.00156 30.4655C3.49722 30.4655 3.71157 30.1053 3.71157 29.7223C3.71157 29.3507 3.49722 29.0351 2.96783 29.0351H1.98793V30.4655H3.00156ZM3.17076 33.7098C3.74479 33.7098 4.02661 33.3154 4.02661 32.8649C4.02661 32.4144 3.75621 32.0428 3.18218 32.0428H1.98845V33.7098H3.17076Z" />
      <path d="M9.6908 27.3462H11.662V33.7103H14.951V35.3997H9.6908V27.3462Z" />
      <path d="M21.8877 27.2115C24.3208 27.2115 26.1005 29.014 26.1005 31.3231C26.1005 33.6888 24.3208 35.5354 21.8877 35.5354C19.4545 35.5354 17.6753 33.6883 17.6753 31.3231C17.6753 29.0135 19.455 27.2115 21.8877 27.2115ZM21.8877 33.7329C23.0928 33.7329 24.0613 32.6969 24.0613 31.3226C24.0613 30.0048 23.0923 29.0135 21.8877 29.0135C20.6825 29.0135 19.7135 30.0048 19.7135 31.3226C19.7135 32.6969 20.6825 33.7329 21.8877 33.7329Z" />
      <path d="M33.365 27.2115C35.3585 27.2115 36.3836 28.3492 36.3836 28.3492L35.4711 29.8356C35.4711 29.8356 34.5696 29.0135 33.4548 29.0135C31.9112 29.0135 31.1794 30.1512 31.1794 31.3117C31.1794 32.5054 31.9678 33.7329 33.4548 33.7329C34.6713 33.7329 35.5947 32.7421 35.5947 32.7421L36.6088 34.1834C36.6088 34.1834 35.4711 35.5349 33.365 35.5349C30.8421 35.5349 29.1413 33.7443 29.1413 31.3558C29.1407 29.0135 30.9433 27.2115 33.365 27.2115Z" />
      <path d="M40.0535 27.3462H42.0247V30.4551H42.8354L44.5025 27.3462H46.6201L44.4464 31.1983V31.2211L46.7441 35.3997H44.5367L42.8359 32.133H42.0252V35.3997H40.054V27.3462H40.0535Z" />
      <path d="M53.6376 27.2115C55.6311 27.2115 56.6562 28.3492 56.6562 28.3492L55.7437 29.8356C55.7437 29.8356 54.8422 29.0135 53.7274 29.0135C52.1838 29.0135 51.452 30.1512 51.452 31.3117C51.452 32.5054 52.2404 33.7329 53.7274 33.7329C54.9439 33.7329 55.8673 32.7421 55.8673 32.7421L56.8814 34.1834C56.8814 34.1834 55.7437 35.5349 53.6376 35.5349C51.1147 35.5349 49.4139 33.7443 49.4139 31.3558C49.4139 29.0135 51.2164 27.2115 53.6376 27.2115Z" />
      <path d="M60.3271 27.3462H62.2983V30.5677H65.2266V27.3462H67.1978V35.3997H65.2266V32.2462H62.2983V35.3997H60.3271V27.3462Z" />
      <path d="M75.3758 33.7103H72.8638L72.3795 35.3997H70.3523L73.089 27.3462H75.1609L77.8982 35.3997H75.8704L75.3758 33.7103ZM74.1141 29.1819C74.1141 29.1819 73.9335 30.0606 73.7871 30.5677L73.3252 32.133H74.9139L74.4634 30.5677C74.317 30.0606 74.1364 29.1819 74.1364 29.1819H74.1141Z" />
      <path d="M81.0501 27.3462H83.0213V35.3997H81.0501V27.3462Z" />
      <path d="M87.1661 27.3462H89.1596L91.3898 31.1085C91.7054 31.6379 92.0661 32.4818 92.0661 32.4818H92.0889C92.0889 32.4818 91.9986 31.6146 91.9986 31.1085V27.3462H93.9698V35.3997H91.9872L89.7461 31.6488C89.4311 31.1189 89.0698 30.275 89.0698 30.275H89.0475C89.0475 30.275 89.1378 31.1423 89.1378 31.6488V35.3997H87.1666V27.3462H87.1661Z" />
      <path d="M102.945 27.3462H107.879V29.0356H104.917V30.7255H107.372V32.4149H104.917V35.3997H102.945V27.3462Z" />
      <path d="M111.268 27.3462H113.239V32.4377C113.239 33.2821 113.791 33.7326 114.636 33.7326C115.481 33.7326 116.032 33.2821 116.032 32.4377V27.3462H118.004V32.4377C118.004 34.2511 116.674 35.5352 114.647 35.5352C112.597 35.5352 111.267 34.2511 111.267 32.4377V27.3462H111.268Z" />
      <path d="M122.036 27.3462H124.029L126.26 31.1085C126.575 31.6379 126.936 32.4818 126.936 32.4818H126.959C126.959 32.4818 126.868 31.6146 126.868 31.1085V27.3462H128.84V35.3997H126.857L124.616 31.6488C124.3 31.1189 123.94 30.275 123.94 30.275H123.917C123.917 30.275 124.008 31.1423 124.008 31.6488V35.3997H122.036V27.3462H122.036Z" />
      <path d="M132.982 27.3462H135.843C138.377 27.3462 140 28.8217 140 31.3561C140 33.8909 138.377 35.3997 135.843 35.3997H132.982V27.3462ZM135.763 33.7103C137.126 33.7103 137.96 32.8991 137.96 31.3561C137.96 29.8245 137.093 29.0356 135.763 29.0356H134.953V33.7103H135.763Z" />
      <path d="M138.521 18.0724H0V18.3942H138.521V18.0724Z" />
      <path d="M139.809 17.0535H102.879V19.1991H139.809V17.0535Z" />
    </svg>
  );
};

export default rockawayLogo;
